body {
  /* root bg color */

  background-color: rgb(248, 248, 248);
  color: "#3b3e41";
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  /* font-family: "M PLUS 1p", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: normal;
  font-variation-settings: normal;
}
